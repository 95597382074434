/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Autocomplete, Box, ButtonGroup, Checkbox, Container, FormControlLabel, IconButton, Paper, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { v4 as uuid } from "uuid";

import SaveIcon from "@mui/icons-material/Save";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DataGrid } from "@mui/x-data-grid";
import { actionsStyle, GridContainer, headerStyle, inputProps, mobileHeaderStyle, rowStyle, labelSpanStyle, textStyle, lineStyle } from "./styles";
import Yup from "../CustomYupValidation";

const ConfigurationsPanelDimensions = (props) => {
    const { t } = useTranslation();
    const { initialConfiguration, onChange, onError, tabIndex, labels, filterLabelId } = props;

    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const [editableRowId, setEditableRowId] = useState(null);
    const [isError, setError] = useState(false);

    const columns = [
        { field: "id", headerName: "Source" },
        { field: "config", headerName: "Config", flex: 1 },
        { field: "code", headerName: "Code", flex: 1 },
    ];
    const doc = [
        /* eslint-disable-next-line */
        {
            id: "dataLayer",
            /* eslint-disable-next-line */
            config: 'var dataLayer = [{"key": "valueFromDataLayer"}];',
            /* eslint-disable-next-line */
            code: 'jan.get("dataLayer", "key");',
        },
        /* eslint-disable-next-line */
        {
            id: "metadata",
            /* eslint-disable-next-line */
            config: 'jancmd("setConfig", {\n' + "    metadata: {\n" + '        "key": "valueFromMetadata",\n' + "    },\n" + "});",
            /* eslint-disable-next-line */
            code: 'jan.get("metadata", "key");',
        },
        /* eslint-disable-next-line */
        {
            id: "event data",
            /* eslint-disable-next-line */
            config: 'jancmd("sendEvent", "eventName", {\n' + '    "key": "valueFromEvent",\n' + "});",
            /* eslint-disable-next-line */
            code: 'jan.get("event", "key");',
        },
    ];

    const validationSchema = Yup.object().shape({
        dimensions: Yup.array().of(
            Yup.object().shape({
                isActive: Yup.boolean().required(),
                name: Yup.string().required(t("configuration.errors.nameRequired")),
                ga3: Yup.object().shape({
                    id: Yup.number()
                        .integer()
                        .min(1, t("configuration.errors.min", { attribute: "id", min: 0 }))
                        .requiredIfNotEmpty("ga3", "value", t("configuration.errors.requiredWhen", { attribute: "GA3.value" }))
                        .nullable(),
                    value: Yup.string().requiredIfActive(t("configuration.errors.requiredIfActive")).validJs(),
                }),
                ga4: Yup.object().shape({
                    name: Yup.string()
                        .requiredIfNotEmpty("ga4", "value", t("configuration.errors.requiredWhen", { attribute: "GA4.value" }))
                        .nullable(),
                    value: Yup.string().requiredIfActive(t("configuration.errors.requiredIfActive")).validJs(),
                }),
                customTrackingPixel: Yup.object().shape({
                    name: Yup.string()
                        .requiredIfNotEmpty("customTrackingPixel", "value", t("configuration.errors.requiredWhen", { attribute: "customTrackingPixel.value" }))
                        .nullable(),
                    value: Yup.string().requiredIfActive(t("configuration.errors.requiredIfActive")).validJs().nullable(),
                }),
            })
        ),
    });

    const { register, watch, formState, control, reset, setValue, getValues, trigger } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            dimensions: initialConfiguration.dimensions,
        },
    }); // initialise the hook
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "dimensions",
    });

    function cancel() {
        setConfirmDeleteId(null);
        setEditableRowId(null);
    }

    const onSave = () => {
        onChange(getValues("dimensions"));
        cancel();
    };

    const onDelete = (dimensionIndex) => {
        remove(dimensionIndex);
        cancel();
        onChange(getValues("dimensions"));
    };

    const getInitDimension = () => {
        const initDimension = {
            name: "",
            uuid: uuid(),
            isActive: false,
            ga3: {
                id: 0,
                value: "",
            },
            ga4: {
                name: "",
                value: "",
            },
            customTrackingPixel: {
                name: "",
                value: "",
            },
        };
        return initDimension;
    };

    const onAdd = () => {
        const dimension = getInitDimension();
        append(dimension);
        setEditableRowId(getValues("dimensions").length);
        trigger();
    };

    useEffect(() => {
        setEditableRowId(null);
        reset({ dimensions: initialConfiguration.dimensions });
    }, [initialConfiguration]);

    useEffect(() => {
        if (Object.keys(errors).length > 0 && isError === false) {
            setError(true);
            onError("dimensions", true);
        }
        if (Object.keys(errors).length === 0 && isError === true) {
            setError(false);
            onError("dimensions", false);
        }
    }, [formState]);

    function keyById(array) {
        const keyedObject = {};
        array.forEach((item) => {
            keyedObject[item.id] = item;
        });
        return keyedObject;
    }

    const labelIds = labels.map((obj) => obj.id);
    const labelsById = keyById(labels);
    const defaultProps = {
        options: labels,
        getOptionLabel: (option) => option.name ?? "",
        isOptionEqualToValue: (option, value) => true,
    };

    return (
        <div>
            {initialConfiguration && (
                <form>
                    <div>
                        <Box sx={GridContainer} display={{ xs: "none", md: "block" }}>
                            <Grid container sx={headerStyle} direction="row">
                                <Grid className="header-name" key="hk1" item xs={12} md={1.25}>
                                    {t("configuration.name")}
                                </Grid>
                                <Grid className="header-label" key="hk6" item xs={12} md={1.25}>
                                    {t("configuration.label")}
                                </Grid>
                                <Grid className="header-ga3" key="hk2" item xs={12} md={2.5}>
                                    {t("configuration.ga3.title")}
                                </Grid>
                                <Grid className="header-ga4" key="hk3" item xs={12} md={2.5}>
                                    {t("configuration.ga4.title")}
                                </Grid>
                                <Grid className="header-customTrackingPixel" key="hk4" item xs={12} md={2.5}>
                                    {t("configuration.customTrackingPixel.title")}
                                </Grid>
                                <Grid className="header-actions" key="header_key_5" item xs={12} md={0.5}>
                                    {t("configuration.actions")}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={GridContainer}>
                            {fields.map((dimension, index) => {
                                function fieldName(field) {
                                    return `dimensions.${index}.${field}`;
                                }

                                function errorField(field, parentField) {
                                    const errorMessage = parentField ? errors?.dimensions?.[index]?.[parentField]?.[field]?.message : errors?.dimensions?.[index]?.[field]?.message;
                                    return errorMessage ?? "";
                                }

                                function label(name, type) {
                                    const tName = t(`common.${name}`);
                                    return `${tName} (${type})`;
                                }

                                const inlineError = errors.dimensions && errors.dimensions[index] !== undefined;
                                const autoCompId = `auto_${index}`;
                                const watchLabelId = watch("dimensions")[index].labelId;
                                const labelId = labelIds.includes(watchLabelId) ? watchLabelId : null;
                                const labelName = labelId && labelIds.includes(labelId) ? labelsById[labelId].name : null;
                                const labelValue = { id: labelId ?? "", name: labelName ?? "" };

                                const hide = filterLabelId > 0 && dimension.labelId !== filterLabelId ? "hide" : "";
                                const odd = index % 2 === 0 ? "even" : "odd";
                                const bError = inlineError ? "bError" : "";
                                return (
                                    <React.Fragment key={dimension.uuid}>
                                        <Grid container key={dimension.uuid} sx={rowStyle} className={`dimension ${odd}  ${bError} ${hide}`}>
                                            <Grid item xs={12} md={1.25}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.name")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.name")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].name}</Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.isActive")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].isActive ? t("common.yes") : t("common.no")}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <TextField sx={textStyle} label={t("common.name")} {...register(fieldName("name"))} error={Boolean(errorField("name"))} helperText={errorField("name") ? errorField("name") : ""} />

                                                        <FormControlLabel
                                                            control={<Controller name={fieldName("isActive")} control={control} render={({ field }) => <Checkbox {...field} checked={watch("dimensions")[index].isActive === true} />} />}
                                                            label={t("common.isActive")}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={1.25}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.label")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.label")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{labelName}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <Autocomplete
                                                            fullWidth
                                                            {...defaultProps}
                                                            id={autoCompId}
                                                            value={labelValue}
                                                            onChange={(e, newValue) => {
                                                                if (newValue) {
                                                                    setValue(`dimensions.${index}.labelId`, newValue.id ?? null);
                                                                    setValue(`dimensions.${index}.labelName`, newValue.name ?? null);
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label={t("common.label")} variant="standard" />}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={2.5}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.ga3.title")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.id")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].ga3.id}</Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.value")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].ga3.value}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <TextField sx={textStyle} label={label("id", "ga3")} {...register(fieldName("ga3.id"))} error={Boolean(errorField("id", "ga3"))} helperText={errorField("id", "ga3")} />
                                                        <TextField
                                                            placeholder={label("value", "ga3")}
                                                            label={t("common.value")}
                                                            {...register(fieldName("ga3.value"))}
                                                            defaultValue={watch("dimensions")[index].ga3?.value}
                                                            error={Boolean(errorField("value", "ga3"))}
                                                            helperText={errorField("value", "ga3")}
                                                            multiline
                                                            InputProps={inputProps}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={2.5}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.ga4.title")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.name")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].ga4.name}</Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} md={12}>
                                                            <Grid sx={labelSpanStyle}>{t("common.value")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].ga4.value}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <TextField sx={textStyle} label={label("name", "ga4")} {...register(fieldName("ga4.name"))} error={Boolean(errorField("name", "ga4"))} helperText={errorField("name", "ga4")} />
                                                        <TextField
                                                            placeholder={label("value", "ga4")}
                                                            label={t("common.value")}
                                                            {...register(fieldName("ga4.value"))}
                                                            defaultValue={watch("dimensions")[index].ga4?.value}
                                                            error={Boolean(errorField("value", "ga4"))}
                                                            helperText={errorField("value", "ga4")}
                                                            multiline
                                                            InputProps={inputProps}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={2.5}>
                                                <Grid item xs={12} display={{ xs: "block", md: "none" }} sx={mobileHeaderStyle}>
                                                    {t("configuration.customTrackingPixel.title")}
                                                </Grid>
                                                {editableRowId !== index && (
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.name")} </Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].customTrackingPixel?.name}</Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid sx={labelSpanStyle}>{t("common.value")}</Grid>
                                                            <Grid sx={{ fontSize: "12px" }}>{watch("dimensions")[index].customTrackingPixel?.value}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {editableRowId === index && (
                                                    <Grid container>
                                                        <TextField
                                                            sx={textStyle}
                                                            label={label("name", "ctp")}
                                                            {...register(fieldName("customTrackingPixel.name"))}
                                                            error={Boolean(errorField("name", "customTrackingPixel"))}
                                                            helperText={errorField("name", "customTrackingPixel")}
                                                        />
                                                        <TextField
                                                            placeholder={label("value", "ctp")}
                                                            label={t("common.value")}
                                                            {...register(fieldName("customTrackingPixel.value"))}
                                                            defaultValue={dimension.customTrackingPixel?.value}
                                                            error={Boolean(errorField("value", "customTrackingPixel"))}
                                                            helperText={errorField("value", "customTrackingPixel")}
                                                            multiline
                                                            InputProps={inputProps}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid sx={lineStyle} display={{ xs: "block", md: "none" }}>
                                                    {" "}
                                                </Grid>
                                            </Grid>

                                            <Grid sx={actionsStyle} key="actions_key_btn" item xs={12} md={0.5}>
                                                <ButtonGroup size="small" aria-label="small button group">
                                                    {index !== confirmDeleteId && editableRowId == null && (
                                                        <IconButton color="error" onClick={() => setConfirmDeleteId(index)}>
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId !== index && confirmDeleteId !== index && (
                                                        <IconButton color="primary" onClick={() => setEditableRowId(index)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </ButtonGroup>

                                                <ButtonGroup size="small" aria-label="small button group">
                                                    {(confirmDeleteId === index || editableRowId === index) && (
                                                        <IconButton color="error" onClick={() => cancel()}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    )}
                                                    {confirmDeleteId === index && (
                                                        <IconButton color="success" onClick={() => onDelete(index)}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === index && (
                                                        <IconButton color="primary" onClick={() => onSave()}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    )}
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Box>

                        <Grid sx={rowStyle} key="actions_key_btn" item xs={12} md={12}>
                            <IconButton color="primary" onClick={() => onAdd()}>
                                <AddCircleOutlineIcon
                                    sx={{
                                        width: 64,
                                        height: 64,
                                        padding: 0,
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </div>
                </form>
            )}
            <div>
                <h2>{t("Documentation")}</h2>
                {/* jai trouve que ca pour resoudre cette erreur :
                MUI: useResizeContainer - The parent DOM element of the data grid has an empty width. */}
                {tabIndex === "dimensions" && (
                    <Container>
                        <Box component={Paper}>
                            <DataGrid autoHeight rows={doc} columns={columns} rowsPerPageOptions={[]} hideFooter disableColumnMenu />
                        </Box>
                    </Container>
                )}
            </div>
        </div>
    );
};

export default ConfigurationsPanelDimensions;

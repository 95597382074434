/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, IconButton, Container, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";
import Yup from "../CustomYupValidation";

import { useGetSitesQuery, useAddNewSiteMutation, useUpdateSiteMutation, useDeleteSiteMutation, useGetCountriesQuery } from "../../services/janApi";
import { actionsStyle, GridContainer, headerStyle, rowHeader, rowStyle } from "../Configurations/styles";

const SitesList = () => {
    const [sortParams, setSortParams] = useState({ column: "name", direction: 1 });
    const { data, isLoading, refetch } = useGetSitesQuery(undefined, {
        selectFromResult: ({ data, isLoading, refetch }) => ({
            data:
                data === undefined
                    ? []
                    : Object.values(data).sort((a, b) => {
                          const columnA = typeof a[sortParams.column] === "object" ? a[sortParams.column].name : a[sortParams.column];
                          const columnB = typeof b[sortParams.column] === "object" ? b[sortParams.column].name : b[sortParams.column];
                          const nameA = columnA.toString().toUpperCase();
                          const nameB = columnB.toString().toUpperCase();
                          if (nameA < nameB) {
                              return -sortParams.direction;
                          }
                          if (nameA > nameB) {
                              return sortParams.direction;
                          }
                          return 0;
                      }),
            isLoading,
            refetch,
        }),
    });
    const [editableRowId, setEditableRowId] = useState(0);
    const [confirmDeleteId, setConfirmDeleteId] = useState(0);
    const [addNewSite] = useAddNewSiteMutation();
    const [updateSite] = useUpdateSiteMutation();
    const [deleteSite] = useDeleteSiteMutation();
    const { t } = useTranslation();
    const { data: countries } = useGetCountriesQuery();

    const validationSchema = Yup.object().shape({
        sites: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .uniqueIn(data, t("sites.form.siteNameIsUsed"))
                    .min(2, t("sites.form.siteNameIsShort"))
                    .required(t("sites.form.siteNameIsRequired"))
                    .matches(/^[aA-zZ0-9_]+$/, t("sites.form.siteNameOnlyAlphabetsAndNumbers")),
                country: Yup.object().shape({
                    id: Yup.number().positive().required(t("common.form.fieldIsRequired")),
                    name: Yup.string(),
                }),
            })
        ),
    });

    const { register, watch, formState, control, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            sites: data ? Object.values(data) : [],
        },
    }); // initialise the hook
    const { errors, isValid } = formState;
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "sites",
    });

    const onCancel = (siteId, siteIndex) => {
        if (siteId === 0) {
            remove(siteIndex);
        } else {
            update(siteIndex, Object.values(data)[siteIndex]);
            setEditableRowId(0);
        }
    };

    const onSave = (siteIndex) => {
        if (!isValid) {
            return false;
        }

        const site = watch(`sites.${siteIndex}`);

        if (site.id > 0) {
            updateSite({ id: site.id, values: site })
                .unwrap()
                .then((payload) => {
                    toast.success(t("sites.form.siteRecorded"));
                    update(siteIndex, payload);
                    setEditableRowId(0);
                })
                .catch((error) => console.log("error", error));
        } else {
            addNewSite(site)
                .unwrap()
                .then((payload) => {
                    toast.success(t("sites.form.siteRecorded"));
                    update(siteIndex, payload);
                })
                .catch((error) => console.log("error", error));
        }

        return false;
    };

    const onDelete = (siteId, siteIndex) => {
        deleteSite({ id: siteId })
            .unwrap()
            .then(() => {
                toast.success(t("sites.form.siteDeleted"));
                remove(siteIndex);
                setConfirmDeleteId(0);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        reset({ sites: data ? Object.values(data) : [] });
    }, [isLoading, sortParams]);

    const displaySortIcon = (columnName) => {
        if (columnName === sortParams.column) {
            return (
                <IconButton
                    onClick={() =>
                        setSortParams({
                            column: columnName,
                            direction: -sortParams.direction,
                        })
                    }
                >
                    {sortParams.direction === 1 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
            );
        }
        return (
            <IconButton onClick={() => setSortParams({ column: columnName, direction: 1 })}>
                <ArrowDownwardIcon color="disabled" />
            </IconButton>
        );
    };

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Container>
                {isLoading && <Box>loading</Box>}

                {!isLoading && (
                    <form>
                        <div>
                            <Box sx={GridContainer}>
                                <Grid container sx={headerStyle} direction="row">
                                    <Grid item sx={rowHeader} xs={1} md={1}>
                                        <Grid item>{displaySortIcon("id")}</Grid>
                                        <Grid item>Id</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={2} md={3}>
                                        <Grid item>{displaySortIcon("name")}</Grid>
                                        <Grid item>{t("common.name")}</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={2} md={3}>
                                        <Grid item>{displaySortIcon("slug")}</Grid>
                                        <Grid item>{t("common.slug")}</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={2} md={3}>
                                        <Grid item>{displaySortIcon("country")}</Grid>
                                        <Grid item>{t("sites.form.country")}</Grid>
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                        {t("configuration.actions")}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={GridContainer}>
                                {fields.map((site, index) => {
                                    const siteId = watch(`sites.${index}.id`);
                                    const siteNameError = errors?.sites?.[index]?.name?.message;
                                    const siteCountryError = errors?.sites?.[index]?.country?.id?.message;
                                    const siteCountryId = `sites.${index}.country.id`;
                                    const OddClass = index % 2 === 0 ? "even" : "odd";

                                    return (
                                        <React.Fragment key={siteId}>
                                            <Grid container key={siteId} sx={rowStyle} className={OddClass}>
                                                <Grid item sx={{ alignItems: "center", justifyContent: "flex-start", display: "flex" }} xs={1} md={1}>
                                                    {siteId}
                                                </Grid>
                                                <Grid item xs={2} md={3}>
                                                    {editableRowId !== siteId && site.name}
                                                    {editableRowId === siteId && <TextField fullWidth label={t("common.name")} control={control} {...register(`sites.${index}.name`)} error={Boolean(siteNameError)} helperText={siteNameError} />}
                                                </Grid>
                                                <Grid item xs={2} md={3} sx={{ display: "flex", alignItems: "center" }}>
                                                    {site.slug}
                                                </Grid>
                                                <Grid item sx={{ alignItems: "center", justifyContent: "flex-start", display: "flex" }} xs={2} md={3}>
                                                    {editableRowId !== siteId && site.country && site.country.name}
                                                    {editableRowId === siteId && (
                                                        <FormControl fullWidth>
                                                            <InputLabel id={`sites.${index}.country_label`}>{t("sites.form.country")}</InputLabel>
                                                            <Controller
                                                                name={siteCountryId}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select {...field} label={t("sites.form.country")} labelId={`sites.${index}.country_label`} error={Boolean(siteCountryError)} helpertext={siteCountryError}>
                                                                        {countries.map((country) => (
                                                                            <MenuItem key={country.id} value={country.id}>
                                                                                {country.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </Grid>
                                                <Grid sx={actionsStyle} item xs={2} md={1}>
                                                    {editableRowId !== siteId && (
                                                        <IconButton color="primary" onClick={() => setEditableRowId(siteId)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === siteId && (
                                                        <IconButton onClick={() => onCancel(siteId, index)}>
                                                            <CancelIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === siteId && (
                                                        <IconButton color="primary" onClick={() => onSave(index)}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId !== siteId && siteId !== confirmDeleteId && (
                                                        <IconButton color="error" onClick={() => setConfirmDeleteId(siteId)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {confirmDeleteId === siteId && siteId !== 0 && (
                                                        <ButtonGroup size="small" aria-label="small button group">
                                                            <Button variant="outlined" onClick={() => setConfirmDeleteId(0)}>
                                                                {t("common.cancel")}
                                                            </Button>
                                                            <Button variant="outlined" color="error" onClick={() => onDelete(siteId, index)}>
                                                                {t("common.confirm")}
                                                            </Button>
                                                        </ButtonGroup>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                                <Grid>
                                    <Grid sx={rowStyle} key="actions_key_btn" item xs={12} md={12}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setEditableRowId(0);
                                                append({
                                                    id: 0,
                                                    name: "",
                                                    country: { id: "", name: "" },
                                                });
                                            }}
                                        >
                                            {t("sites.form.siteAdd")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </form>
                )}
            </Container>
        </div>
    );
};

export default SitesList;

import React from "react";
import { useSelector } from "react-redux";

import { Ring } from "react-awesome-spinners";

import "./App.css";

function Spinner() {
    const spinnerVisible = useSelector((state) => state.spinner.visible);

    if (spinnerVisible) {
        return (
            <div className="spinner">
                <Ring size={80} />
            </div>
        );
    }
    return null;
}

export default Spinner;

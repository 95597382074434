const navconfig = {
    title: "modules.users",
    icon: "people",
    tabs: [
        /*
        { label: "users.list", url: "/users" },
        { label: "super", url: "/users?a=1" },
        { label: "bof bof", url: "/users/bofbof" },
        { label: "ouaf ouaf", url: "/users/ouafouaf" },
        */
    ],
};

export default navconfig;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Grid, FormControlLabel, FormGroup, Switch, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Yup from "../CustomYupValidation";

const ConfigurationsPanelAdmin = (props) => {
    const { t } = useTranslation();
    const { onChange, initialConfiguration, onError } = props;
    const [isError, setError] = useState(false);
    const [canAddProperty, setAddProperty] = useState(true);

    const validationSchema = Yup.object().shape({
        ga3: Yup.object().shape({
            id: Yup.string()
                .nullable()
                .matches(/^[aA-zZ0-9-]+$/, t("configuration.errors.alphaDash"))
                .when("active", {
                    is: true,
                    then: (validationSchema) => validationSchema.required(t("common.form.fieldIsRequired")),
                }),
        }),
        ga4: Yup.object().shape({
            id: Yup.string()
                .nullable()
                .matches(/^[aA-zZ0-9-]+$/, t("configuration.errors.alphaDash"))
                .when("active", {
                    is: true,
                    then: (validationSchema) => validationSchema.required(t("common.form.fieldIsRequired")),
                }),
            cookieDomain: Yup.string().nullable(),
            cookieExpires: Yup.string().nullable(),
            cookiePrefix: Yup.string()
                .matches(/^[aA-zZ0-9-]+$/, t("configuration.errors.alphaDash"))
                .nullable(),
            cookieFlags: Yup.string().nullable(),
            userProperties: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string()
                        .nullable()
                        .matches(/^[aA-zZ0-9-]+$/, t("configuration.errors.alphaDash")),
                    value: Yup.string().validJs(),
                })
            ),
        }),
        customTrackingPixel: Yup.object().shape({
            eventTypeKey: Yup.string()
                .nullable()
                .when("active", {
                    is: true,
                    then: (validationSchema) => validationSchema.required(t("common.form.fieldIsRequired")),
                }),
            customDimensionKey: Yup.string()
                .nullable()
                .when("active", {
                    is: true,
                    then: (validationSchema) => validationSchema.required(t("common.form.fieldIsRequired")),
                }),
            baseUrl: Yup.string()
                .nullable()
                .when("active", {
                    is: true,
                    then: (validationSchema) => validationSchema.required(t("common.form.fieldIsRequired")),
                })
                .url(t("configuration.errors.url")),
        }),
    });

    const { register, formState, getValues, reset, control, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: initialConfiguration.admin,
    });
    const { errors } = formState;

    const handleFormChange = () => {
        onChange(getValues());
    };

    const debouncedHandleFormChange = debounce(handleFormChange, 500);

    const customRegister = (field) => {
        const customField = register(field);
        return {
            onChange: (e) => {
                customField.onChange(e);
                debouncedHandleFormChange();
            },
            onBlur: customField.onBlur,
            name: customField.name,
            ref: customField.ref,
            inputRef: customField.ref,
        };
    };

    const deleteUserProperty = (index) => {
        const { userProperties } = getValues().ga4;
        userProperties.splice(index);
        setValue("ga4.userProperties", userProperties);
        handleFormChange();
    };

    const addUserProperty = () => {
        const { userProperties } = getValues().ga4;
        if (canAddProperty) {
            userProperties.push({ name: "", value: "" });
            setValue("ga4.userProperties", userProperties);
            handleFormChange();
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0 && isError === false) {
            setError(true);
            onError("admin", true);
        }
        if (Object.keys(errors).length === 0 && isError === true) {
            setError(false);
            onError("admin", false);
        }

        const { userProperties } = getValues().ga4;
        setAddProperty(!userProperties.some((obj) => obj.name === "" && obj.value === ""));
    }, [formState, canAddProperty]);

    useEffect(() => {
        reset(initialConfiguration.admin);
    }, [initialConfiguration]);

    return (
        <div>
            {initialConfiguration && (
                <form>
                    <Grid item md={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card sx={{ width: "100%" }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {t("configuration.ga3.title")}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga3.active"
                                                        control={<Controller control={control} render={(field) => <Switch {...customRegister("ga3.active")} checked={getValues().ga3.active} />} />}
                                                        label={t("common.active")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={8} />
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...customRegister("ga3.id")}
                                                    label={t("configuration.ga3.id")}
                                                    error={Boolean(errors.ga3?.id)}
                                                    helperText={errors.ga3?.id ? errors.ga3.id.message : ""}
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={8} />
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga3.triggerOnConsent"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga3.triggerOnConsent")} checked={getValues().ga3.triggerOnConsent} />} />}
                                                        label={t("configuration.triggerOnConsent")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={8} />
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("ga3.cookieExpires")}
                                                    label={t("configuration.cookieExpires")}
                                                    error={Boolean(errors.ga3?.cookieExpires)}
                                                    helperText={errors.ga3?.cookieExpires ? errors.ga3.cookieExpires : ""}
                                                    size="small"
                                                    InputProps={{ startAdornment: <InputAdornment position="start">seconds</InputAdornment> }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={8} />
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga3.cookieUpdate"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga3.cookieUpdate")} checked={getValues().ga3.cookieUpdate} />} />}
                                                        label={t("configuration.cookieUpdate")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card sx={{ width: "100%" }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {t("configuration.ga4.title")}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga4.active"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga4.active")} checked={getValues().ga4.active} />} />}
                                                        label={t("common.active")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("ga4.cookieDomain")}
                                                    label={t("configuration.cookieDomain")}
                                                    error={Boolean(errors.ga4?.cookieDomain)}
                                                    helperText={errors.ga4?.cookieDomain ? errors.ga4.cookieDomain : ""}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga4.cookieUpdate"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga4.cookieUpdate")} checked={getValues().ga4.cookieUpdate} />} />}
                                                        label={t("configuration.cookieUpdate")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("ga4.id")}
                                                    label={t("configuration.ga4.id")}
                                                    size="small"
                                                    error={Boolean(errors.ga4?.id)}
                                                    helperText={errors.ga4?.id ? errors.ga4.id : ""}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("ga4.cookieExpires")}
                                                    label={t("configuration.cookieExpires")}
                                                    error={Boolean(errors.ga4?.cookieExpires)}
                                                    helperText={errors.ga4?.cookieExpires ? errors.ga4.cookieExpires : ""}
                                                    size="small"
                                                    InputProps={{ startAdornment: <InputAdornment position="start">seconds</InputAdornment> }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("ga4.cookieFlags")}
                                                    label={t("configuration.cookieFlags")}
                                                    error={Boolean(errors.ga4?.cookieFlags)}
                                                    helperText={errors.ga4?.cookieFlags ? errors.ga4.cookieFlags : ""}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga4.triggerOnConsent"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga4.triggerOnConsent")} checked={getValues().ga4.triggerOnConsent} />} />}
                                                        label={t("configuration.triggerOnConsent")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("ga4.cookiePrefix")}
                                                    label={t("configuration.cookiePrefix")}
                                                    error={Boolean(errors.ga4?.cookiePrefix)}
                                                    helperText={errors.ga4?.cookiePrefix ? errors.ga4.cookiePrefix : ""}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga4.allowGoogleSignals"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga4.allowGoogleSignals")} checked={getValues().ga4.allowGoogleSignals} />} />}
                                                        label={t("configuration.ga4.allowGoogleSignals")}
                                                    />
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="ga4.allowAdPersonalizationSignals"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("ga4.allowAdPersonalizationSignals")} checked={getValues().ga4.allowAdPersonalizationSignals} />} />}
                                                        label={t("configuration.ga4.allowAdPersonalizationSignals")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <h4>{t("configuration.ga4.userProperties")}</h4>
                                                {getValues().ga4.userProperties.map((property, index) => {
                                                    const key = `c_${index}_o`;
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <Grid item xs={12} md={12} container sx={{ marginBottom: "10px" }}>
                                                                <Grid container sx={{ display: "flex", gap: "10px" }}>
                                                                    <Grid item xs={12} md={4}>
                                                                        <TextField
                                                                            fullWidth
                                                                            {...customRegister(`ga4.userProperties.${index}.name`)}
                                                                            label={t("common.name")}
                                                                            error={Boolean(errors.ga4?.userProperties[index]?.name?.message)}
                                                                            helperText={errors.ga4?.userProperties[index]?.name?.message}
                                                                            size="small"
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <TextField
                                                                            fullWidth
                                                                            {...customRegister(`ga4.userProperties.${index}.value`)}
                                                                            label={t("common.value")}
                                                                            error={Boolean(errors.ga4?.userProperties[index]?.value?.message)}
                                                                            helperText={errors.ga4?.userProperties[index]?.value?.message}
                                                                            size="small"
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <IconButton color="error" onClick={() => deleteUserProperty(index)}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <IconButton color="primary" onClick={() => addUserProperty()}>
                                                    <AddCircleOutlineIcon
                                                        sx={{
                                                            width: 32,
                                                            height: 32,
                                                            padding: 0,
                                                        }}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card sx={{ width: "100%" }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {t("configuration.customTrackingPixel.title")}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="customTrackingPixel.active"
                                                        control={<Controller control={control} render={({ field }) => <Switch {...customRegister("customTrackingPixel.active")} checked={getValues().customTrackingPixel.active} />} />}
                                                        label={t("common.active")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("customTrackingPixel.eventTypeKey")}
                                                    label={t("configuration.customTrackingPixel.eventTypeKey")}
                                                    error={Boolean(errors.customTrackingPixel?.eventTypeKey)}
                                                    helperText={errors.customTrackingPixel?.eventTypeKey ? errors.customTrackingPixel.eventTypeKey : ""}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={4} />
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("customTrackingPixel.baseUrl")}
                                                    label={t("configuration.customTrackingPixel.baseUrl")}
                                                    error={Boolean(errors.customTrackingPixel?.baseUrl)}
                                                    helperText={errors.customTrackingPixel?.baseUrl ? errors.customTrackingPixel.baseUrl.message : ""}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    {...customRegister("customTrackingPixel.customDimensionKey")}
                                                    label={t("configuration.customTrackingPixel.customDimensionKey")}
                                                    error={Boolean(errors.customTrackingPixel?.customDimensionKey)}
                                                    helperText={errors.customTrackingPixel?.customDimensionKey ? errors.customTrackingPixel.customDimensionKey : ""}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} />
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="customTrackingPixel.triggerOnConsent"
                                                        control={
                                                            <Controller control={control} render={({ field }) => <Switch {...customRegister("customTrackingPixel.triggerOnConsent")} checked={getValues().customTrackingPixel.triggerOnConsent} />} />
                                                        }
                                                        label={t("configuration.triggerOnConsent")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        name="customTrackingPixel.encodeCustomDimension"
                                                        control={
                                                            <Controller
                                                                control={control}
                                                                render={({ field }) => <Switch {...customRegister("customTrackingPixel.encodeCustomDimension")} checked={getValues().customTrackingPixel.encodeCustomDimension} />}
                                                            />
                                                        }
                                                        label={t("configuration.customTrackingPixel.encodeCustomDimension")}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4} />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </div>
    );

    // const navigate = useNavigate();

    // stocker le résultat de la query dans le state
    // envoyer chaque bout du state à un composant
    // chaque composant doit signaler le changement au parent pour maintenir le state
};

export default ConfigurationsPanelAdmin;

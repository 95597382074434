import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import "./services/i18n";

import "react-toastify/dist/ReactToastify.css";

import CssBaseline from "@mui/material/CssBaseline";
import { GoogleOAuthProvider } from "@react-oauth/google";

import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import * as Constants from "./constants";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CssBaseline />
            <React.Suspense fallback="Loading...">
                <GoogleOAuthProvider clientId={Constants.GOOGLE_CLIENT_ID}>
                    <App />
                </GoogleOAuthProvider>
            </React.Suspense>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

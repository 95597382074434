import { TextareaAutosize } from "@mui/material";

const headerStyle = {
    textAlign: "center",
    minHeight: "4em",
    marginBottom: "2px",
    justifyContent: "space-around",
    alignItems: "center",
    alignContent: "space-around",
    flexWrap: "wrap",
    flexGrow: 1,
    gap: "2px",
};
const rowStyle = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    direction: "row",
    justifyContent: "space-around",
    alignItems: "stretch",
    alignContent: "space-around",
    flexWrap: "wrap",
    flexGrow: 1,
    gap: "4px",
    "& .MuiTextField-root": {
        m: 1,
        width: 1,
        marginLeft: 0,
    },
    "& .MuiFormLabel-root": {
        lineHeight: "20px",
        height: "20px",
        top: "-6px",
    },
    "& .MuiFormLabel-filled": { top: "2px" },
};

const actionsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
const mobileHeaderStyle = {
    width: "100%",
    backgroundColor: "rgb(0, 30, 60)",
    color: "white",
    paddingLeft: "10px",
    borderRadius: "8px",
    marginBottom: "10px",
};
const lineErrorStyle = {
    width: "2px",
    backgroundColor: "red",
    color: "red",
    minHeight: "4em",
};

const inputProps = {
    inputComponent: TextareaAutosize,
    inputProps: {
        style: {
            resize: "auto",
        },
    },
};

const filedStyle = {
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& .MuiTextField-root": {
        m: 1,
        width: 1,
        marginLeft: 0,
    },
    "& .MuiInputBase-root": {
        height: 40,
        border: "solid red",
    },
    "& .MuiOutlinedInput-root": {
        height: 40,
        width: 1,
        m: 1,
        border: "solid red",
    },
    "& textarea": {
        height: "40px !important",
        border: "solid lime",
        borderRadius: 1,
        width: 1,
    },
    border: "solid blue",
};

const GridContainer = {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "8px",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    width: "100%",
    gap: "3px",
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: "2px",
};
const textStyle = {
    "& .MuiInputBase-root": {
        height: 40,
    },
};
const textareaStyle = {
    "& .MuiInputBase-root": {
        minHeight: 50,
    },
};
const errorMuiFormHelperText = {
    color: "#d32f2f",
};
const labelSpanStyle = {
    borderRadius: "6px",
    fontWeight: "bold",
    minWidth: "50px !important",
    fontSize: "12px",
    display: "inline-block",
    paddingRight: "5px",
};
const lineStyle = {
    backgroundColor: "#34515e",
    color: "#34515e",
    height: "1px",
    margin: "15px 0",
};
const selectStyle = {
    "& .MuiOutlinedInput-root": {
        height: 40,
        width: "100%",
    },
    "& .MuiFormControl-root": {
        marginTop: "5px",
        marginBottom: "5px",
    },
};
const rowHeader = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
};
const filterHeader = {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
};

const quoteStyle = {
    borderRadius: "10px",
    border: "1px solid",
    borderLeft: "8px solid",
    borderColor: "rgb(255 220 72)",
    backgroundColor: "rgb(255 249 235)",
    padding: "10px 20px",
    margin: "20px 0",
};

const eventActionsStyle = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
};

export {
    headerStyle,
    rowStyle,
    actionsStyle,
    inputProps,
    mobileHeaderStyle,
    textStyle,
    textareaStyle,
    filedStyle,
    GridContainer,
    labelSpanStyle,
    lineStyle,
    selectStyle,
    errorMuiFormHelperText,
    lineErrorStyle,
    rowHeader,
    quoteStyle,
    eventActionsStyle,
};

import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import "./App.css";

import { ToastContainer } from "react-toastify";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";

import theme from "../theme";
import RIGHTS from "../rights";
import RequireAuth from "../helpers/RequireAuth";
import RequireRight from "../helpers/RequireRight";

import MainLayout from "./MainLayout";
import NotFound from "./NotFound";
import Login from "./Login";
import Spinner from "./Spinner";
import UsersList from "./Users/UsersList";
import UsersEdit from "./Users/UsersEdit";
import ConfigurationsList from "./Configurations/ConfigurationsList";
import ConfigurationsEdit from "./Configurations/ConfigurationsEdit";
import CountriesList from "./Countries/CountriesList";
import EventsList from "./Events/EventsList";
import SitesList from "./Sites/SitesList";
import LabelsList from "./labels/LabelsList";

const App = () => {
    const locale = useSelector((state) => state.auth.locale);

    // mui uses enUS and not en-US
    const themeWithLocale = useMemo(() => createTheme(theme, locales[locale.replace("-", "")]), [locale]);

    return (
        <ThemeProvider theme={themeWithLocale}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <MainLayout />
                            </RequireAuth>
                        }
                    >
                        <Route
                            path="/configurations"
                            exact
                            element={
                                <RequireRight right={RIGHTS.configurations}>
                                    <ConfigurationsList />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/configurations/edit"
                            element={
                                <RequireRight right={RIGHTS.configurations}>
                                    <ConfigurationsEdit />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/users"
                            exact
                            element={
                                <RequireRight right={RIGHTS.users}>
                                    <UsersList />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/users/:id"
                            exact
                            element={
                                <RequireRight right={RIGHTS.users}>
                                    <UsersEdit />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/countries"
                            exact
                            element={
                                <RequireRight right={RIGHTS.countries}>
                                    <CountriesList />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/events"
                            exact
                            element={
                                <RequireRight right={RIGHTS.events}>
                                    <EventsList />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/sites"
                            exact
                            element={
                                <RequireRight right={RIGHTS.sites}>
                                    <SitesList />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/labels"
                            exact
                            element={
                                <RequireRight right={RIGHTS.labels}>
                                    <LabelsList />
                                </RequireRight>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </BrowserRouter>
            <Spinner />
            <ToastContainer closeButton={false} autoClose={3000} closeOnClick pauseOnFocusLoss={false} />
        </ThemeProvider>
    );
};

export default App;

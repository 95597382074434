import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = ({ children }) => {
    const username = useSelector((state) => state.auth.username);
    const { pathname, search } = useLocation();

    const isAuthenticated = username !== null;
    if (isAuthenticated) {
        return children;
    }
    return isAuthenticated ? children : <Navigate to="/login" state={{ from: pathname + search }} />;
};

export default RequireAuth;

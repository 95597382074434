import { createSlice, createAsyncThunk, getDefaultMiddleware } from "@reduxjs/toolkit";
import axios from "axios";

const oAuth = (data) => axios({ url: "/oauth", method: "POST", data });

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) =>
    oAuth(data)
        .then((response) => response.data.data)
        .catch((error) => {
            const code = error.code === "ECONNABORTED" ? "ECONNABORTED" : error.response.status;
            return Promise.reject(new Error(code));
        })
);

const initialState = {
    token: null,
    locale: "fr-FR",
    username: null,
    avatar: null,
    rights: [],
    acls: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
    reducers: {
        clearToken: (state) => {
            state.token = null;
            axios.defaults.headers.common.Authorization = "";
            window.localStorage.removeItem("c_token");
        },
        setCustomToken: (state, action) => {
            state.token = action.payload;
            axios.defaults.headers.common.Authorization = `Bearer ${action.payload}`;
            window.localStorage.setItem("c_token", state.token);
        },
        setAvatar: (state, action) => {
            state.avatar = action.payload;
        },
        setLocale: (state, action) => {
            state.locale = action.payload;
        },
        resetUsername: (state, action) => {
            state.username = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.username = `${action.payload.first_name} ${action.payload.last_name}`;
                state.rights = Array.from(new Set(action.payload.acls.map((item) => item.module)));
                state.acls = action.payload.acls;
            })
            .addCase(login.rejected, (state) => {
                state.username = null;
            });
    },
});
export const { clearToken, setAvatar, setCustomToken, setLocale, resetUsername } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;

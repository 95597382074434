/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, IconButton, Container, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";
import Yup from "../CustomYupValidation";

import { useGetLabelsQuery, useAddNewLabelMutation, useUpdateLabelMutation, useDeleteLabelMutation, useGetCountriesQuery } from "../../services/janApi";
import { actionsStyle, GridContainer, headerStyle, rowHeader, rowStyle } from "../Configurations/styles";

const LabelsList = () => {
    const [sortParams, setSortParams] = useState({ column: "name", direction: 1 });
    const { data, isLoading, refetch } = useGetLabelsQuery(undefined, {
        selectFromResult: ({ data, isLoading, refetch }) => ({
            data:
                data === undefined
                    ? []
                    : Object.values(data).sort((a, b) => {
                          const columnA = typeof a[sortParams.column] === "object" ? a[sortParams.column].name : a[sortParams.column];
                          const columnB = typeof b[sortParams.column] === "object" ? b[sortParams.column].name : b[sortParams.column];
                          const nameA = columnA.toString().toUpperCase();
                          const nameB = columnB.toString().toUpperCase();
                          if (nameA < nameB) {
                              return -sortParams.direction;
                          }
                          if (nameA > nameB) {
                              return sortParams.direction;
                          }
                          return 0;
                      }),
            isLoading,
            refetch,
        }),
    });
    const [editableRowId, setEditableRowId] = useState(0);
    const [confirmDeleteId, setConfirmDeleteId] = useState(0);
    const [addNewLabel] = useAddNewLabelMutation();
    const [updateLabel] = useUpdateLabelMutation();
    const [deleteLabel] = useDeleteLabelMutation();
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        labels: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().uniqueIn(data, t("labels.form.labelNameIsUsed")).min(2, t("labels.form.labelNameIsShort")).required(t("labels.form.labelNameIsRequired")),
            })
        ),
    });

    const { register, watch, formState, control, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            labels: data ? Object.values(data) : [],
        },
    }); // initialise the hook
    const { errors, isValid } = formState;
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "labels",
    });

    const onCancel = (labelId, labelIndex) => {
        if (labelId === 0) {
            remove(labelIndex);
        } else {
            update(labelIndex, Object.values(data)[labelIndex]);
            setEditableRowId(0);
        }
    };

    const onSave = (labelIndex) => {
        if (!isValid) {
            return false;
        }

        const label = watch(`labels.${labelIndex}`);

        if (label.id > 0) {
            updateLabel({ id: label.id, values: label })
                .unwrap()
                .then((payload) => {
                    toast.success(t("labels.form.labelRecorded"));
                    update(labelIndex, payload);
                    setEditableRowId(0);
                })
                .catch((error) => console.log("error", error));
        } else {
            addNewLabel(label)
                .unwrap()
                .then((payload) => {
                    toast.success(t("labels.form.labelRecorded"));
                    update(labelIndex, payload);
                })
                .catch((error) => console.log("error", error));
        }

        return false;
    };

    const onDelete = (labelId, labelIndex) => {
        deleteLabel({ id: labelId })
            .unwrap()
            .then(() => {
                toast.success(t("labels.form.labelDeleted"));
                remove(labelIndex);
                setConfirmDeleteId(0);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        reset({ labels: data ? Object.values(data) : [] });
    }, [isLoading, sortParams]);

    const displaySortIcon = (columnName) => {
        if (columnName === sortParams.column) {
            return (
                <IconButton
                    onClick={() =>
                        setSortParams({
                            column: columnName,
                            direction: -sortParams.direction,
                        })
                    }
                >
                    {sortParams.direction === 1 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
            );
        }
        return (
            <IconButton onClick={() => setSortParams({ column: columnName, direction: 1 })}>
                <ArrowDownwardIcon color="disabled" />
            </IconButton>
        );
    };

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Container>
                {isLoading && <Box>loading</Box>}

                {!isLoading && (
                    <form>
                        <div>
                            <Box sx={GridContainer}>
                                <Grid container sx={headerStyle} direction="row">
                                    <Grid item sx={rowHeader} xs={1} md={1}>
                                        <Grid item>{displaySortIcon("id")}</Grid>
                                        <Grid item>Id</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={2} md={3}>
                                        <Grid item>{displaySortIcon("name")}</Grid>
                                        <Grid item>{t("common.name")}</Grid>
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                        {t("configuration.actions")}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={GridContainer}>
                                {fields.map((label, index) => {
                                    const labelId = watch(`labels.${index}.id`);
                                    const labelNameError = errors?.labels?.[index]?.name?.message;
                                    const OddClass = index % 2 === 0 ? "even" : "odd";

                                    return (
                                        <React.Fragment key={labelId}>
                                            <Grid container key={labelId} sx={rowStyle} className={OddClass}>
                                                <Grid item sx={{ alignItems: "center", justifyContent: "flex-start", display: "flex" }} xs={1} md={1}>
                                                    {labelId}
                                                </Grid>
                                                <Grid item xs={2} md={3}>
                                                    {editableRowId !== labelId && label.name}
                                                    {editableRowId === labelId && <TextField fullWidth label={t("common.name")} control={control} {...register(`labels.${index}.name`)} error={Boolean(labelNameError)} helperText={labelNameError} />}
                                                </Grid>
                                                <Grid sx={actionsStyle} item xs={2} md={1}>
                                                    {editableRowId !== labelId && (
                                                        <IconButton color="primary" onClick={() => setEditableRowId(labelId)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === labelId && (
                                                        <IconButton onClick={() => onCancel(labelId, index)}>
                                                            <CancelIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === labelId && (
                                                        <IconButton color="primary" onClick={() => onSave(index)}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId !== labelId && labelId !== confirmDeleteId && (
                                                        <IconButton color="error" onClick={() => setConfirmDeleteId(labelId)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {confirmDeleteId === labelId && labelId !== 0 && (
                                                        <ButtonGroup size="small" aria-label="small button group">
                                                            <Button variant="outlined" onClick={() => setConfirmDeleteId(0)}>
                                                                {t("common.cancel")}
                                                            </Button>
                                                            <Button variant="outlined" color="error" onClick={() => onDelete(labelId, index)}>
                                                                {t("common.confirm")}
                                                            </Button>
                                                        </ButtonGroup>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                                <Grid>
                                    <Grid sx={rowStyle} key="actions_key_btn" item xs={12} md={12}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setEditableRowId(0);
                                                append({
                                                    id: 0,
                                                    name: "",
                                                });
                                            }}
                                        >
                                            {t("labels.form.labelAdd")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </form>
                )}
            </Container>
        </div>
    );
};

export default LabelsList;

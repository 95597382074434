const MODULES = [
    {
        id: "configurations",
        children: [
            {
                id: "configurations",
                icon: "dnsRounded",
                path: "/configurations/edit",
                right: "configurations",
            },
            {
                id: "countries",
                path: "/countries",
                icon: "public",
                right: "countries",
            },
            {
                id: "events",
                path: "/events",
                icon: "celebration",
                right: "events",
            },
            {
                id: "sites",
                path: "/sites",
                icon: "web",
                right: "sites",
            },
            {
                id: "labels",
                path: "/labels",
                icon: "web",
                right: "labels",
            },
        ],
    },
    {
        id: "admin",
        children: [
            {
                id: "users",
                path: "/users",
                icon: "people",
                right: "users",
            },
        ],
    },
];

export default MODULES;

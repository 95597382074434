/* eslint func-names: ["error", "never"] */
import { JSHINT } from "jshint/dist/jshint";
import * as Yup from "yup";
import { Trans } from "react-i18next";

Yup.addMethod(Yup.mixed, "requiredIfNotEmpty", function (parentField, field, message) {
    return this.test("requiredIfNotEmpty", message, (value, testContext) => {
        const { path, createError } = testContext;
        const origin = testContext?.from[1]?.value;
        const fieldName = origin[parentField] ? origin[parentField][field] : undefined;
        if (value === undefined && fieldName) {
            return createError({ path, message });
        }
        return true;
    });
});

Yup.addMethod(Yup.string, "requiredIfActive", function (message) {
    return this.test("requiredIfActive", message, (value, testContext) => {
        const { path, createError } = testContext;
        const origin = testContext?.from[1]?.value;
        const isActive = origin?.isActive === true;
        if (isActive && (testContext.parent.id || testContext.parent.name) && (value === "" || value === null || value === undefined)) {
            return createError({ path, message });
        }
        return true;
    });
});

Yup.addMethod(Yup.string, "validJs", function () {
    return this.test("validJs", (value, testContext) => {
        const { path, createError } = testContext;
        const cleanValue = typeof value === "string" ? value : "";
        const source = [cleanValue];
        const options = { undef: true, asi: true, expr: true };
        const preDef = { window: false, dataLayer: false, jan: false };
        JSHINT(source, options, preDef);
        let jsMessages = null;
        if (cleanValue.match("^[0-9]*/0+$")) {
            jsMessages = " divide by zero";
            return createError({ path, message: `JS Error: ${jsMessages}` });
        }

        if (JSHINT.data().errors !== undefined && JSHINT.data().errors.length > 0) {
            const errors = [];
            /* eslint-disable-next-line */
            for (const error of JSHINT.data().errors) {
                errors.push(error.raw.replace("{a}", error.evidence));
            }
            jsMessages = errors.join(" - ");

            return createError({ path, message: `JS Error: ${jsMessages}` });
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, "uniqueIn", function (list, message) {
    return this.test("uniqueIn", message, (value, testContext) => {
        const { path, createError } = testContext;
        if (value && list.filter((r) => r.name.toLowerCase() === value.toLowerCase() && r.id !== testContext.parent.id).length >= 1) {
            return createError({ path, message });
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, "uniqueEventId", (message) =>
    Yup.string().test("uniqueEventId", message, (value, testContext) => {
        const { events } = testContext.from[1].value;
        if (value) {
            return events.filter((e) => parseInt(e.id, 10) === parseInt(value, 10)).length < 2;
        }
        return true;
    })
);

Yup.addMethod(Yup.mixed, "ifAdminActive", function (isActive, message) {
    return this.test("ifAdminActive", message, (value, testContext) => {
        const { path, createError } = testContext;
        if (!value && isActive) {
            return createError({ path, message });
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, "validDimension", function (dimensions, message) {
    return this.test("validDimension", message, (value, testContext) => {
        const { path, createError } = testContext;
        if (value !== undefined && value !== null) {
            if (dimensions !== undefined && dimensions.length > 0) {
                const validNames = dimensions.map((d) => d.name);
                const words = value.split(",").map((w) => w.trim());
                const notAvailable = words.filter((w) => !validNames.includes(w.trim())).filter((w) => w !== "");
                if (notAvailable.length > 0) {
                    /* eslint-disable-next-line */
                    const m = <Trans i18nKey="configuration.forbiddenWords" values={{ words: notAvailable.join(", ") }} />;
                    return createError({ path, message: m });
                }
            }
        }

        return true;
    });
});

export default Yup;

import { configureStore, isPending, isFulfilled, isRejectedWithValue, isRejected } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import authReducer from "./slices/auth";
import spinnerReducer, { showSpinner, hideSpinner } from "./slices/spinner";
import { janApi } from "./services/janApi";

export const spinnerMiddleware = (storeAPI) => (next) => (action) => {
    if (isPending(action)) {
        storeAPI.dispatch(showSpinner());
    }
    if (isFulfilled(action) || isRejected(action) || isRejectedWithValue(action)) {
        storeAPI.dispatch(hideSpinner());
    }

    return next(action);
};

const store = configureStore({
    reducer: {
        auth: authReducer,
        spinner: spinnerReducer,
        [janApi.reducerPath]: janApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(janApi.middleware, spinnerMiddleware),
    devTools: true,
});

export default store;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

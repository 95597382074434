import * as React from "react";

import Typography from "@mui/material/Typography";

const AccessDenied = () => (
    <main>
        <Typography component="h6" variant="h6" color="text.primary" gutterBottom>
            Access denied
        </Typography>
    </main>
);
export default AccessDenied;

import * as React from "react";

import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";

function NotFound() {
    return (
        <main>
            <Container>
                <Typography component="h6" variant="h6" color="text.primary" gutterBottom>
                    Not found
                </Typography>
            </Container>
        </main>
    );
}

export default NotFound;

import React from "react";
import { useSelector } from "react-redux";

import AccessDenied from "../components/AccessDenied";

const RequireRight = ({ children, right }) => {
    const userRights = useSelector((state) => state.auth.rights);

    const userHasRequiredRole = userRights.includes(right);

    if (userHasRequiredRole) {
        return children;
    }
    return <AccessDenied />;
};

export default RequireRight;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Container, Grid, Button, ListItemIcon, ListItemText, IconButton } from "@mui/material";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WarningIcon from "@mui/icons-material/Warning";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { toast } from "react-toastify";

import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { string } from "yup";
import _ from "lodash";

import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import moment from "moment";
import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";
import { useLazyGetSitesWithAclQuery, useLazyGetConfigurationsQuery, useAddConfigurationMutation, useUpdateConfigurationMutation, useLazyGetLabelsQuery, useLazyGetEventsQuery } from "../../services/janApi";
import ConfigurationsPanelAdmin from "./ConfigurationsPanelAdmin";
import ConfigurationsPanelEvents from "./ConfigurationsPanelEvents";
import ConfigurationsPanelDimensions from "./ConfigurationsPanelDimensions";
import { API_BASE_URL } from "../../constants";

// reste a faire:
// https://www.figma.com/file/QRU9fXXia1RqWCdJdPpAgr/jan-admin?node-id=0%3A1

const ConfigurationsEdit = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const siteId = searchParams.get("siteId");
    const id = searchParams.get("id");
    const [addConfiguration] = useAddConfigurationMutation();
    const [updateConfiguration] = useUpdateConfigurationMutation();
    const [configurationId, setConfigurationId] = useState(id ? parseInt(id, 10) : "");
    const [sites, setSites] = useState([]);
    const [labels, setLabels] = useState([]);
    const [allEvents, setEvents] = useState([]);
    const [configurationSiteId, setConfigurationSiteId] = useState(siteId ?? "");
    const [filterLabelId, setFilterLabelId] = useState(0);
    const [tabIndex, setTabIndex] = useState("admin");
    const [versions, setVersions] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [testOpen, setTestOpen] = useState(false);
    const [testUrl, setTestUrl] = useState("");
    const [adminTabWarning, setAdminTabWarning] = useState(false);
    const [eventsTabWarning, setEventsTabWarning] = useState(false);
    const [dimensionsTabWarning, setDimensionsTabWarning] = useState(false);
    const [configurationToCopy, setConfigurationToCopy] = useState(null);
    const [pasteOpen, setPasteOpen] = useState(false);
    const mapTabWarning = {
        admin: { set: setAdminTabWarning, get: adminTabWarning },
        events: { set: setEventsTabWarning, get: eventsTabWarning },
        dimensions: { set: setDimensionsTabWarning, get: dimensionsTabWarning },
    };
    const STATUS_ACTIVE = "ACTIVE";
    const STATUS_INACTIVE = "INACTIVE";
    const JAN_TEST_KEY = "jan_testSiteConfig";
    const initEvent = {
        id: 0,
        isActive: false,
        ga3: {
            type: "pageview",
            isActive: true,
            nonInteraction: false,
            dimensions: [],
            c_dimensions: "",
        },
        ga4: {
            type: "pageview",
            category: "",
            subcategory: "",
            isActive: true,
            nonInteraction: false,
            dimensions: [],
            c_dimensions: "",
        },
        customTrackingPixel: {
            name: "pageview",
            isActive: false,
            dimensions: [],
            c_dimensions: "",
        },
    };
    const initDimension = {
        name: "",
        labelId: "",
        uuid: uuid(),
        isActive: false,
        ga3: {
            id: 0,
            value: "",
        },
        ga4: {
            name: "",
            value: "",
        },
        customTrackingPixel: {
            name: "",
            value: "",
        },
    };
    const initConfig = {
        admin: {
            ga3: {
                id: "",
                active: true,
                triggerOnConsent: false,
                cookieExpires: "",
                cookieUpdate: false,
            },
            ga4: {
                id: "",
                active: false,
                triggerOnConsent: false,
                cookieExpires: "",
                cookieUpdate: false,
                cookieDomain: "",
                cookiePrefix: "",
                cookieFlags: "",
                allowGoogleSignals: false,
                allowAdPersonalizationSignals: false,
                userProperties: [{ name: "", value: "" }],
            },
            customTrackingPixel: {
                active: false,
                triggerOnConsent: true,
                baseUrl: "",
                eventTypeKey: "",
                customDimensionKey: "",
                encodeCustomDimension: true,
            },
        },
        events: [initEvent],
        dimensions: [initDimension],
    };
    const [getEvents] = useLazyGetEventsQuery();
    const [getLabels] = useLazyGetLabelsQuery();

    const [initialConfiguration, setInitialConfiguration] = useState(initConfig);
    const [currentConfiguration, setCurrentConfiguration] = useState(initConfig);
    const [currentStatus, setCurrentStatus] = useState(STATUS_INACTIVE);
    const [usedLabels, setUsedLabels] = useState([]);
    const [getSites, resultSites] = useLazyGetSitesWithAclQuery(module, {});

    const loadSites = (module) => {
        getSites(module)
            .unwrap()
            .then((sites) => {
                setSites(sites);
            });
    };

    const loadEvents = () => {
        getEvents()
            .unwrap()
            .then((events) => {
                setEvents(events);
            });
    };
    const loadLabels = () => {
        getLabels()
            .unwrap()
            .then((labels) => {
                setLabels(labels);
            });
    };

    const [getConfigurations, resultConfigurations] = useLazyGetConfigurationsQuery(configurationSiteId, {
        skip: configurationSiteId === "",
    });

    const updateConfig = (config) => {
        const newConfig = JSON.parse(JSON.stringify(config));
        Object.keys(newConfig.events).forEach((k) => {
            newConfig.events[k].ga3.c_dimensions = "";
            newConfig.events[k].ga4.c_dimensions = "";
        });
        return newConfig;
    };
    const setULabels = (config) => {
        const all = [{ id: 0, name: t("common.all") }];
        getEvents()
            .unwrap()
            .then((events) => {
                setEvents(events);
                getLabels()
                    .unwrap()
                    .then((labels) => {
                        setLabels(labels);
                        const dimensionLabelIds = config.dimensions.filter((obj) => obj.labelId).map((obj) => obj.labelId);
                        const eventIds = config.events.filter((obj) => obj.id).map((obj) => obj.id);
                        const eventLabelIds = events
                            .filter((obj) => eventIds.includes(obj.id))
                            .filter((obj) => obj.label_id)
                            .map((obj) => obj.label_id);
                        const labelIds = [...dimensionLabelIds, ...eventLabelIds];

                        const uLabels = labels.filter(({ id }) => labelIds.includes(id));
                        const sortedLabels = _.sortBy(uLabels, "name");
                        setUsedLabels([...all, ...sortedLabels]);
                    });
            });
    };

    const loadConfigurations = (siteId, configId) => {
        getConfigurations(siteId)
            .unwrap()
            .then((versionsList) => {
                if (versionsList.length > 0) {
                    setVersions(versionsList);
                    let config = "";
                    if (configId === "") {
                        setConfigurationId(versionsList[0].id);
                        config = updateConfig(versionsList[0].config);
                        setInitialConfiguration(config);
                        setCurrentConfiguration(config);
                        setCurrentStatus(versionsList[0].status);
                        setULabels(config);
                    } else {
                        config = versionsList.filter((version) => version.id === configId)[0].config;
                        config = updateConfig(config);
                        setInitialConfiguration(config);
                        setCurrentConfiguration(config);
                        setCurrentStatus(versionsList.filter((version) => version.id === configId)[0].status);
                        setULabels(config);
                    }
                } else {
                    setVersions([]);
                    setConfigurationId("");
                    setInitialConfiguration(initConfig);
                    setCurrentConfiguration(initConfig);
                }
                setIsLoaded(true);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        // premier chargement si un site est present dans l url
        loadLabels();
        loadEvents();
        loadSites("configuration");
        if (configurationSiteId !== "") {
            loadConfigurations(configurationSiteId, configurationId);
        } else {
            setInitialConfiguration(initConfig);
            setCurrentConfiguration(initConfig);
        }
    }, []);

    const handleChangeVersion = (event) => {
        setConfigurationId(event.target.value);
        const config = updateConfig(versions.filter((version) => version.id === event.target.value)[0].config);
        setInitialConfiguration(config);
        setCurrentConfiguration(config);
        setCurrentStatus(versions.filter((version) => version.id === event.target.value)[0].status);
        setUsedLabels(versions.filter((version) => version.id === event.target.value)[0].used_labels);
        navigate(`/configurations/edit?siteId=${configurationSiteId}&id=${event.target.value}`, { replace: true });
    };

    // exception pour forcer formik a reloader le meme init config quand on switch de site pas encore confiugre
    const checkInitialConfiguration = () => {
        if (configurationId === "") {
            setInitialConfiguration({ ...initConfig, admin: { ...initConfig.admin, active: false } });
        }
    };

    const handleChangeSite = (event) => {
        checkInitialConfiguration();
        setConfigurationSiteId(event.target.value);
        setConfigurationId("");
        setVersions([]);
        loadConfigurations(event.target.value, "");
        navigate(`/configurations/edit?siteId=${event.target.value}`, { replace: true });
    };

    const handleChangeTab = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const handleChangeConfigurationAdmin = (value) => {
        setCurrentConfiguration({ ...currentConfiguration, admin: value });
    };

    const handleChangeConfigurationEvents = (value) => {
        setCurrentConfiguration({ ...currentConfiguration, events: value });
    };

    const handleChangeConfigurationDimensions = (value) => {
        setCurrentConfiguration({ ...currentConfiguration, dimensions: value });
    };

    const filterByLabelId = (event) => {
        setFilterLabelId(event.target.value);
    };

    const onSave = () => {
        if (Object.values(mapTabWarning).filter((w) => w.get === true).length > 0) {
            toast.error(t("configuration.errors.general"));
        } else if (_.isEqual(currentConfiguration, initialConfiguration)) {
            toast.error(t("configuration.errors.no_change"));
        } else {
            addConfiguration({ site_id: configurationSiteId, config: currentConfiguration })
                .unwrap()
                .then((payload) => {
                    toast.success(t("sites.form.siteRecorded"));
                    setVersions([payload, ...versions]);
                    setConfigurationId(payload.id);
                    setCurrentStatus(payload.status);
                    setInitialConfiguration(currentConfiguration);
                    navigate(`/configurations/edit?siteId=${configurationSiteId}&id=${payload.id}`, { replace: true });
                })
                .catch((error) => console.log("error", error));
        }
    };

    const onPublish = () => {
        if (Object.values(mapTabWarning).filter((w) => w.get === true).length > 0) {
            toast.error(t("configuration.errors.general"));
        } else {
            updateConfiguration({ id: configurationId, values: { site_id: configurationSiteId, action: "publish" } })
                .unwrap()
                .then((payload) => {
                    toast.success(t("configuration.published"));
                    const updatedVersionsList = versions.map((version) => {
                        if (version.id === configurationId) {
                            return { ...version, status: STATUS_ACTIVE };
                        }
                        if (version.id !== configurationId && version.status === STATUS_ACTIVE) {
                            return { ...version, status: STATUS_INACTIVE };
                        }
                        return version;
                    });
                    setVersions(updatedVersionsList);
                })
                .catch((error) => console.log("error", error));
        }
    };

    const getTabProps = (tab) => {
        const tabLabel = tab.charAt(0).toUpperCase() + tab.slice(1).toLowerCase();
        const tabProps = { label: tabLabel, value: tab };
        if (mapTabWarning[tab].get) {
            tabProps.label = (
                <span
                    style={{
                        display: "inline-flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <WarningIcon color="error" style={{ marginRight: "8px" }} /> {tabLabel}
                </span>
            );
        }
        return tabProps;
    };

    const handleError = (tab, isError) => {
        const tabWarningSetter = mapTabWarning[tab].set;
        tabWarningSetter(isError);
    };

    const getCurrentDimensions = () => ({
        ids: currentConfiguration.dimensions
            .map((d) => ({ uuid: d.uuid, name: d.name }))
            .sort((a, b) => {
                const nameA = a.name.toString().toUpperCase();
                const nameB = b.name.toString().toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
            .map((d) => d.uuid),
        list: currentConfiguration.dimensions,
    });

    const handleTestOpen = () => setTestOpen(true);

    const handleTestClose = () => setTestOpen(false);

    const onTest = () => {
        if (string().url().isValidSync(testUrl)) {
            const redirectUrl = `${testUrl}#${JAN_TEST_KEY}=${encodeURIComponent(`${API_BASE_URL}/configurationtest/${configurationId === versions[0].id ? "latest" : configurationId}?site_id=${configurationSiteId}`)}`;
            window.open(redirectUrl, "_blank");
            handleTestClose();
        } else {
            toast.error(t("configuration.test.error"));
        }
    };

    const auth = useSelector((state) => state.auth);

    const canPublish = () => {
        const countryId = sites.find((site) => site.id === parseInt(configurationSiteId, 10))?.country?.id;
        return auth.acls.some((acl) => acl.name === "country.configuration.publish" && acl.country_id === countryId);
    };
    const canSave = () => {
        const countryId = sites.find((site) => site.id === parseInt(configurationSiteId, 10))?.country?.id;
        return auth.acls.some((acl) => acl.name === "country.configuration.write" && acl.country_id === countryId);
    };

    const disablePublish = () => configurationId === "" || currentStatus === STATUS_ACTIVE || !canPublish();
    const disableSave = () => !canSave();
    const localeDate = (date) => moment(date).utc(true).local().format("YYYY-MM-DD HH:mm:ss");

    const onCopy = () => {
        const siteName = sites.find((site) => site.id === parseInt(configurationSiteId, 10))?.name;
        const version = versions.find((version) => version.id === configurationId);
        const copyLabel = `${siteName} ${localeDate(version.created_at)} (${version.user.first_name} ${version.user.last_name})`;
        setConfigurationToCopy({
            id: configurationId,
            label: copyLabel,
            config: initialConfiguration,
        });
        toast.success(t("configuration.success_copy", { name: copyLabel }));
    };

    function keyById(array) {
        const keyedObject = {};
        array.forEach((item) => {
            keyedObject[item.id] = item;
        });
        return keyedObject;
    }

    const labelsById = keyById(labels);

    const onPaste = () => {
        setInitialConfiguration(configurationToCopy.config);
        setCurrentConfiguration(configurationToCopy.config);
        setConfigurationToCopy(null);
        setPasteOpen(false);
    };

    const canCopy = () => configurationId !== "" && (configurationToCopy === null || configurationToCopy.id !== configurationId);

    const canPaste = () => configurationToCopy !== null && configurationToCopy.id !== configurationId;

    const handlePasteOpen = () => setPasteOpen(true);

    const handlePasteClose = () => setPasteOpen(false);

    return (
        <div>
            {true && (
                <>
                    <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />

                    <Container maxWidth="full">
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="site-label">Site</InputLabel>
                                    <Select labelId="site-label" id="site-select" value={configurationSiteId} onChange={handleChangeSite} label="Site">
                                        {sites !== undefined &&
                                            sites.map((site) => (
                                                <MenuItem key={site.id} value={site.id}>
                                                    {site.name} ({site.country.name})
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="version-label">Version</InputLabel>
                                    <Select labelId="version-label" id="version-select" value={configurationId} onChange={handleChangeVersion} label="Version">
                                        {versions !== undefined &&
                                            versions.map((version) => (
                                                <MenuItem key={version.id} value={version.id} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                                    {version.status === STATUS_ACTIVE && (
                                                        <Grid sx={{ display: "flex" }}>
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    color: "success.main",
                                                                }}
                                                                sx={{ flex: "none", marginRight: "5px" }}
                                                            >
                                                                {localeDate(version.created_at)} ({version.user.first_name} {version.user.last_name})
                                                            </ListItemText>
                                                            <ListItemIcon>
                                                                <OnlinePredictionIcon fontSize="small" color="success" />
                                                            </ListItemIcon>
                                                        </Grid>
                                                    )}
                                                    {version.status !== STATUS_ACTIVE && (
                                                        <ListItemText>
                                                            {localeDate(version.created_at)} ({version.user.first_name} {version.user.last_name})
                                                        </ListItemText>
                                                    )}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={1} sx={{ display: "flex" }}>
                                <Tooltip title={t("common.copy")}>
                                    <IconButton color="primary" onClick={() => onCopy()} disabled={!canCopy()}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={t("common.paste")}>
                                    <IconButton color="primary" onClick={() => handlePasteOpen()} disabled={!canPaste()}>
                                        <ContentPasteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container spacing={1} justifyContent="flex-end">
                                    <Grid item>
                                        <Button variant="contained" onClick={() => onSave()} disabled={disableSave()}>
                                            {t("common.submit")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" color="secondary" onClick={() => handleTestOpen()} disabled={configurationId === ""}>
                                            {t("common.test")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="success" onClick={() => onPublish()} disabled={disablePublish()}>
                                            {t("common.publish")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" sx={{ marginTop: "20px" }}>
                            <Grid item xs={12} md={2}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="used-label-ids" sx={{ letterSpacing: "0.1em" }}>
                                        {t("configuration.FilterByLabel")}
                                    </InputLabel>
                                    <Select sx={{ letterSpacing: "0.7em", width: "100%" }} labelId="used-label-ids" id="site-select" value={filterLabelId} onChange={filterByLabelId} label="Label">
                                        {usedLabels !== undefined &&
                                            usedLabels.map((label) => (
                                                <MenuItem key={label.id} value={label.id}>
                                                    {label.id > 0 ? label.name : t(label.name)}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container maxWidth="full">
                        {configurationSiteId && (
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs value={tabIndex} onChange={handleChangeTab} variant="fullWidth">
                                        <Tab {...getTabProps("admin")} />
                                        <Tab {...getTabProps("events")} />
                                        <Tab {...getTabProps("dimensions")} />
                                    </Tabs>
                                </Box>
                                <Grid container spacing={2} justifyContent="space-between">
                                    <Grid item xs={12} md={12} style={{ display: tabIndex === "admin" ? "block" : "none" }}>
                                        <ConfigurationsPanelAdmin initialConfiguration={initialConfiguration} onChange={handleChangeConfigurationAdmin} onError={handleError} />
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ display: tabIndex === "events" ? "block" : "none" }}>
                                        {isLoaded && (
                                            <ConfigurationsPanelEvents
                                                initialConfiguration={initialConfiguration}
                                                onChange={handleChangeConfigurationEvents}
                                                initEvent={initEvent}
                                                getCurrentDimensions={getCurrentDimensions}
                                                currentAdmin={currentConfiguration.admin}
                                                labels={labels}
                                                filterLabelId={filterLabelId}
                                                onError={handleError}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ display: tabIndex === "dimensions" ? "block" : "none" }}>
                                        <ConfigurationsPanelDimensions
                                            initialConfiguration={initialConfiguration}
                                            onChange={handleChangeConfigurationDimensions}
                                            initDimension={initDimension}
                                            labels={labels}
                                            filterLabelId={filterLabelId}
                                            onError={handleError}
                                            tabIndex={tabIndex}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        <Dialog open={testOpen} onClose={handleTestClose}>
                            <DialogTitle>{t("configuration.test.title")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{t("configuration.test.description")}</DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="test_url"
                                    label="url"
                                    type="url"
                                    fullWidth
                                    variant="standard"
                                    value={testUrl}
                                    onChange={(e) => {
                                        setTestUrl(e.target.value);
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleTestClose}>{t("common.cancel")}</Button>
                                <Button onClick={onTest}>{t("common.test")}</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={pasteOpen} onClose={handlePasteClose}>
                            <DialogTitle>{t("configuration.paste.title")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{t("configuration.paste.description", { configurationLabel: configurationToCopy?.label })}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handlePasteClose}>{t("common.cancel")}</Button>
                                <Button onClick={onPaste}>{t("common.paste")}</Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </>
            )}
        </div>
    );
};

export default ConfigurationsEdit;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, IconButton, Container, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";
import Yup from "../CustomYupValidation";

import { useGetCountriesQuery, useAddNewCountryMutation, useUpdateCountryMutation, useDeleteCountryMutation } from "../../services/janApi";
import { actionsStyle, GridContainer, headerStyle, rowHeader, rowStyle } from "../Configurations/styles";

const CountriesList = () => {
    const [sortParams, setSortParams] = useState({ column: "name", direction: 1 });
    const { data, isLoading, refetch } = useGetCountriesQuery(undefined, {
        selectFromResult: ({ data, isLoading, refetch }) => ({
            data:
                data === undefined
                    ? []
                    : Object.values(data).sort((a, b) => {
                          const nameA = a[sortParams.column].toString().toUpperCase();
                          const nameB = b[sortParams.column].toString().toUpperCase();
                          if (nameA < nameB) {
                              return -sortParams.direction;
                          }
                          if (nameA > nameB) {
                              return sortParams.direction;
                          }
                          return 0;
                      }),
            isLoading,
            refetch,
        }),
    });

    const [editableRowId, setEditableRowId] = useState(0);
    const [confirmDeleteId, setConfirmDeleteId] = useState(0);
    const [addNewCountry] = useAddNewCountryMutation();
    const [updateCountry] = useUpdateCountryMutation();
    const [deleteCountry] = useDeleteCountryMutation();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        countries: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .uniqueIn(data, t("countries.form.countryNameIsUsed"))
                    .min(2, t("countries.form.countryNameIsShort"))
                    .required(t("countries.form.countryNameIsRequired"))
                    .matches(/^[aA-zZ\s]+$/, t("countries.form.countryNameOnlyAlphabets")),
            })
        ),
    });

    const { register, watch, formState, control, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            countries: data ? Object.values(data) : [],
        },
    }); // initialise the hook
    const { errors, isValid } = formState;
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "countries",
    });

    const onCancel = (countryId, countryIndex) => {
        if (countryId === 0) {
            remove(countryIndex);
        } else {
            update(countryIndex, Object.values(data)[countryIndex]);
            setEditableRowId(0);
        }
    };

    const onSave = (countryIndex) => {
        if (!isValid) {
            return false;
        }

        const currentCountry = watch(`countries.${countryIndex}`);

        if (currentCountry.id > 0) {
            updateCountry({
                id: currentCountry.id,
                values: currentCountry,
            })
                .unwrap()
                .then(() => {
                    toast.success(t("countries.form.countryRecorded"));
                    update(countryIndex, currentCountry);
                    setEditableRowId(0);
                })
                .catch((error) => console.log("error", error));
        } else {
            addNewCountry(currentCountry)
                .unwrap()
                .then((payload) => {
                    toast.success(t("countries.form.countryRecorded"));
                    currentCountry.id = payload.id;
                    update(countryIndex, currentCountry);
                })
                .catch((error) => console.log("error", error));
        }

        return false;
    };

    const onDelete = (countryId, countryIndex) => {
        deleteCountry({
            id: countryId,
        })
            .unwrap()
            .then(() => {
                toast.success(t("countries.form.countryDeleted"));
                remove(countryIndex);
                setConfirmDeleteId(0);
            })
            .catch((error) => console.log("error", error));
    };

    const displaySortIcon = (columnName) => {
        if (columnName === sortParams.column) {
            return <IconButton onClick={() => setSortParams({ column: columnName, direction: -sortParams.direction })}>{sortParams.direction === 1 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</IconButton>;
        }
        return (
            <IconButton onClick={() => setSortParams({ column: columnName, direction: 1 })}>
                <ArrowDownwardIcon color="disabled" />
            </IconButton>
        );
    };

    useEffect(() => {
        reset({ countries: data ? Object.values(data) : [] });
    }, [isLoading, sortParams]);

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Container>
                {isLoading && <Box>loading</Box>}

                {!isLoading && (
                    <form>
                        <div>
                            <Box sx={GridContainer}>
                                <Grid container sx={headerStyle} direction="row">
                                    <Grid item sx={rowHeader} xs={1} md={1}>
                                        <Grid item>{displaySortIcon("id")}</Grid>
                                        <Grid item>Id</Grid>
                                    </Grid>
                                    <Grid item sx={rowHeader} xs={3} md={6}>
                                        <Grid item>{displaySortIcon("name")}</Grid>
                                        <Grid item>{t("common.name")}</Grid>
                                    </Grid>
                                    <Grid item xs={3} md={1}>
                                        {t("configuration.actions")}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={GridContainer}>
                                {fields.map((country, index) => {
                                    const countryId = watch(`countries.${index}.id`);
                                    const countryNameError = errors?.countries?.[index]?.name?.message;
                                    const OddClass = index % 2 === 0 ? "even" : "odd";
                                    return (
                                        <React.Fragment key={countryId}>
                                            <Grid container key={countryId} sx={rowStyle} className={OddClass}>
                                                <Grid item xs={1} md={1}>
                                                    {countryId}
                                                </Grid>
                                                <Grid item xs={3} md={6}>
                                                    {editableRowId !== countryId && country.name}
                                                    {editableRowId === countryId && (
                                                        <TextField fullWidth label={t("common.name")} control={control} {...register(`countries.${index}.name`)} error={Boolean(countryNameError)} helperText={countryNameError} />
                                                    )}
                                                </Grid>
                                                <Grid sx={actionsStyle} item xs={3} md={1}>
                                                    {editableRowId !== countryId && (
                                                        <IconButton color="primary" onClick={() => setEditableRowId(countryId)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === countryId && (
                                                        <IconButton onClick={() => onCancel(countryId, index)}>
                                                            <CancelIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId === countryId && (
                                                        <IconButton color="primary" onClick={() => onSave(index)}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    )}
                                                    {editableRowId !== countryId && countryId !== confirmDeleteId && (
                                                        <IconButton color="error" onClick={() => setConfirmDeleteId(countryId)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {confirmDeleteId === countryId && countryId !== 0 && (
                                                        <ButtonGroup size="small" aria-label="small button group">
                                                            <Button variant="outlined" onClick={() => setConfirmDeleteId(0)}>
                                                                {t("common.cancel")}
                                                            </Button>
                                                            <Button variant="outlined" color="error" onClick={() => onDelete(countryId, index)}>
                                                                {t("common.confirm")}
                                                            </Button>
                                                        </ButtonGroup>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                                <Grid>
                                    <Grid sx={rowStyle} key="actions_key_btn" item xs={12} md={12}>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                append({
                                                    id: 0,
                                                    name: "",
                                                })
                                            }
                                        >
                                            {t("countries.form.countryAdd")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </form>
                )}
            </Container>
        </div>
    );
};

export default CountriesList;

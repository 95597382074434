/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import * as Yup from "yup";

import { Button, Card, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";

import { useGetLanguagesQuery, useGetUserQuery, useAddNewUserMutation, useUpdateUserMutation, useLazyCheckEmailExistsQuery, useGetCountriesQuery, useGetPermissionsQuery } from "../../services/janApi";

const UsersEdit = () => {
    let { id } = useParams();

    id = parseInt(id, 10);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const newUser = {
        first_name: "",
        last_name: "",
        email: "",
        language: { id: 2 },
        is_active: false,
        acls: [],
    };

    const {
        data: user = newUser,
        isLoading: isFetchingUser,
        refetch,
    } = useGetUserQuery(id, {
        skip: id === 0,
    });
    const { data: languages = [], isLoading: isFetchingLanguages } = useGetLanguagesQuery();
    const { data: countries = [], isLoading: isFetchingCountries } = useGetCountriesQuery();
    const { data: permissions = [], isLoading: isFetchingPermissions } = useGetPermissionsQuery();

    const [updateUser] = useUpdateUserMutation();
    const [addNewUser] = useAddNewUserMutation();

    const [trigger] = useLazyCheckEmailExistsQuery();
    const debouncedCheckEmailExists = useMemo(() => debounce(trigger, 500), []);
    useEffect(() => {
        debouncedCheckEmailExists.cancel();
    }, []);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required(t("common.form.fieldIsRequired")),
        last_name: Yup.string().required(t("common.form.fieldIsRequired")),
        email: Yup.string()
            .email()
            .required(t("users.form.emailIsRequired"))
            .test("checkEmailUnique", t("common.form.emailAlreadyRegistered"), async (email) => {
                const response = await debouncedCheckEmailExists(email);
                if (typeof response !== "undefined") {
                    if (response.data.length > 0) {
                        const idFromCheck = parseInt(response.data[0].id, 10);
                        return idFromCheck === id || id === 0;
                    }
                    return true;
                }
                return true;
            }),
    });

    const { register, watch, formState, control, handleSubmit, reset, setValue, getValues } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: user,
    });
    const { errors, isValid } = formState;

    const save = (values) => {
        if (!isValid) {
            return false;
        }

        if (id === 0) {
            addNewUser(values)
                .unwrap()
                .then((payload) => {
                    toast.success("super !");
                    navigate(`/users/${payload.id}`, {
                        replace: true,
                    });
                })
                .catch((error) => console.log("error", error));
        } else {
            updateUser({ id, values })
                .unwrap()
                .then((user) => {
                    toast.success(t("users.form.userRecorded"));
                    reset(user);
                    refetch();
                })
                .catch((error) => console.log("error", error));
        }

        return true;
    };

    const firstNameError = errors?.first_name?.message;
    const lastNameError = errors?.last_name?.message;
    const emailError = errors?.email?.message;

    useEffect(() => {
        reset(user);
    }, [isFetchingUser, isFetchingLanguages]);

    const handleRightsChange = (event) => {
        const { checked } = event.target;
        const permissionId = parseInt(event.target.getAttribute("data-permission-id"), 10);
        const dataCountryId = event.target.getAttribute("data-country-id");
        const countryId = dataCountryId ? parseInt(dataCountryId, 10) : null;
        const permissionName = event.target.getAttribute("data-permission-name");

        if (checked) {
            setValue("acls", [
                ...getValues("acls"),
                {
                    permission_id: permissionId,
                    country_id: countryId,
                    name: permissionName,
                },
            ]);
        } else {
            setValue(
                "acls",
                getValues("acls").filter((v) => !(v.permission_id === permissionId && v.country_id === countryId))
            );
        }
    };

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Container>
                <Typography variant="h5">Edition</Typography>
                <Card variant="outlined" sx={{ p: 2 }}>
                    {!isFetchingUser && !isFetchingLanguages && !isFetchingCountries && !isFetchingPermissions && (
                        <form onSubmit={handleSubmit(save)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth label={t("users.first_name")} {...register("first_name")} error={Boolean(firstNameError)} helperText={firstNameError} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label={t("users.last_name")} {...register("last_name")} error={Boolean(lastNameError)} helperText={lastNameError} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Email" {...register("email")} error={Boolean(emailError)} helperText={emailError} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch {...register("is_active")} defaultChecked={user.is_active} />} label={t("users.form.isActive")} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <InputLabel id="language-label">{t("users.form.language")}</InputLabel>
                                        <Controller
                                            name="language.id"
                                            control={control}
                                            defaultValue={2}
                                            render={({ field }) => (
                                                <Select {...field} label={t("users.form.language")} labelId="language-label">
                                                    {languages.map((lang) => (
                                                        <MenuItem key={lang.id} value={lang.id}>
                                                            {lang.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid> {t("users.rights_by_country")}</Grid>
                                    {countries.map((country) => (
                                        <Grid key={country.id}>
                                            <Grid>{country.name}</Grid>
                                            <Grid sx={{ marginLeft: "30px" }}>
                                                {Object.keys(permissions.country).map((moduleName) => (
                                                    <Grid key={moduleName}>
                                                        <Grid>{moduleName}</Grid>
                                                        <Grid sx={{ marginLeft: "30px" }}>
                                                            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                                                                {permissions.country[moduleName].map((permission) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...register(`${country.id}-${moduleName}-${permission.action}`)}
                                                                                defaultValue=""
                                                                                key={`${country.id}-${moduleName}-${permission.action}`}
                                                                                checked={watch("acls").some((acl) => acl.permission_id === permission.id && acl.country_id === country.id)}
                                                                                defaultChecked={user.acls.some((acl) => acl.permission_id === permission.id && acl.country_id === country.id)}
                                                                                onChange={handleRightsChange}
                                                                                inputProps={{
                                                                                    "data-permission-id": `${permission.id}`,
                                                                                    "data-country-id": `${country.id}`,
                                                                                    "data-module-name": `${moduleName}`,
                                                                                    "data-permission-name": `${permission.name}`,
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={t(permission.action)}
                                                                        key={`${country.id}-${moduleName}-${permission.action}`}
                                                                    />
                                                                ))}
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid> {t("users.rights_by_module")}</Grid>
                                    <Grid sx={{ marginLeft: "30px" }}>
                                        {Object.keys(permissions.instance).map((moduleName) => (
                                            <Grid key={moduleName}>
                                                <Grid>{moduleName}</Grid>
                                                <Grid sx={{ marginLeft: "30px" }}>
                                                    <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                                                        {permissions.instance[moduleName].map((permission) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        {...register(`${moduleName}-${permission.action}`)}
                                                                        defaultValue=""
                                                                        key={`${moduleName}-${permission.action}`}
                                                                        defaultChecked={user.acls.some((acl) => acl.permission_id === permission.id)}
                                                                        checked={watch("acls").some((acl) => acl.permission_id === permission.id)}
                                                                        onChange={handleRightsChange}
                                                                        disabled={permission.action === "read"}
                                                                        inputProps={{
                                                                            "data-permission-id": `${permission.id}`,
                                                                            "data-country-id": null,
                                                                            "data-module-name": `${moduleName}`,
                                                                            "data-permission-name": `${permission.name}`,
                                                                        }}
                                                                    />
                                                                }
                                                                label={permission.action}
                                                                key={`${moduleName}-${permission.action}`}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button color="primary" variant="contained" type="submit">
                                        {t("common.submit")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Card>
            </Container>
        </div>
    );
};

export default UsersEdit;

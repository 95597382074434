import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";

const ConfigurationsList = () => {
    const a = 1;

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />

            <Container>
                voilà la liste des configs {a}
                <br />
                <Link to="/configurations/edit?siteId=1&id=123">Site 1</Link>
            </Container>
        </div>
    );

    // const { t } = useTranslation();

    // const navigate = useNavigate();

    // stocker le résultat de la query dans le state
    // envoyer chaque bout du state à un composant
    // chaque composant doit signaler le changement au parent pour maintenir le state
};

export default ConfigurationsList;

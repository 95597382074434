import { createSlice } from "@reduxjs/toolkit";

const initialState = { visible: false };

const spinnerSlice = createSlice({
    name: "spinner",
    initialState,
    reducers: {
        showSpinner: (state) => {
            state.visible = true;
        },
        hideSpinner: (state) => {
            state.visible = false;
        },
    },
});
export const { showSpinner, hideSpinner } = spinnerSlice.actions;
const { reducer } = spinnerSlice;
export default reducer;

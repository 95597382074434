import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Container, Grid, formLabelClasses, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";

import ModuleNav from "../ModuleNav";
import navconfig from "./navconfig";

import { useGetUsersQuery } from "../../services/janApi";

const columns = [
    { field: "id", headerName: "ID", maxWidth: 80, flex: 0.2 },
    {
        field: "Name",
        headerName: "Name",
        valueGetter: (params) => `${params.row.first_name || ""} ${params.row.last_name || ""}`,
        flex: 2,
    },
    { field: "email", headerName: "Email", flex: 2 },
    {
        field: "edit",
        headerName: "",
        renderCell: (cellValues) => (
            <IconButton color="primary" component={Link} to={`/users/${cellValues.row.id}`}>
                <EditIcon />
            </IconButton>
        ),
    },
];

const UsersList = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { data, isLoading, refetch } = useGetUsersQuery();

    const handleRefetchUsers = () => {
        refetch();
    };

    const [sortModel, setSortModel] = React.useState([
        {
            field: "Name",
            sort: "asc",
        },
    ]);

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />

            <Container>
                <Grid container justifyContent="flex-end">
                    <Button variant="contained" onClick={() => navigate("/users/0")}>
                        {t("users.addAUser")}
                    </Button>
                </Grid>
            </Container>
            <Container>
                {isLoading && <Box>loading</Box>}
                {!isLoading && (
                    <Box component={Paper}>
                        <DataGrid autoHeight rows={Object.values(data)} sortModel={sortModel} onSortModelChange={(model) => setSortModel(model)} columns={columns} />
                    </Box>
                )}
            </Container>
        </div>
    );
};

export default UsersList;

const RIGHTS = {
    configurations: "configurations",
    users: "users",
    countries: "countries",
    events: "events",
    sites: "sites",
    labels: "labels",
};

export default RIGHTS;
